@tailwind base;
@tailwind components;
@tailwind utilities;

/* Responsive Table Styles */
@media (max-width: 640px) {
  .responsive-text-xs {
    font-size: 0.9rem; /* Smaller font size on small screens */
  }
  .responsive-px-6 {
    padding-left: 0.5rem; /* Smaller horizontal padding */
    padding-right: 0.5rem;
  }
  .responsive-py-3 {
    padding-top: 0.5rem; /* Smaller vertical padding */
    padding-bottom: 0.5rem;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* src/index.css or src/Notification.module.css */
@keyframes slide-in {
  from {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0);
  }
}

@keyframes slide-out {
  from {
    transform: translateX(0);
  }
  to {
    transform: translateX(100%);
  }
}

.notification-slide-in {
  animation: slide-in 0.5s forwards;
}

.notification-slide-out {
  animation: slide-out 0.5s forwards;
}

/* Custom CSS for the switch */
.toggle-checkbox:checked {
  right: 0;
  transition: all 0.3s ease-in-out;
  border-color: #fb923c ;
}

.toggle-checkbox:checked + .toggle-label {
  background-color: #fb923c ;
}

.toggle-checkbox {
  transition: all 0.3s ease-in-out;
  /* border-color: #ef4444; for red*/
  /* border-color: #3b82f6; for blue */
  border-color: #6b7280;
}

.toggle-label {
  width: 4rem;
}


/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */
/* 
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
} */
